<template>
  <modal ref="myModal"
         title="批量发货"
         cancel-text="关闭"
         width="95%"
         :button-disabled="true"
         :loading="loading"
         @ok="handleStartSubmit"
         @closed="onClosed"
         :ok-text="loading ? '正在提交，请勿做任何操作' : '提交'"
  >

    <div class="container">
      <div class="card-panel" style="width: 320px; margin-right: 20px;">
        <div class="header">
          <span class="title">基础设置</span>
        </div>
        <div class="body">
          <a-form-model :model="baseForm" class="small-form">
            <a-form-model-item label="自定义标识" prop="customId" class="app_required-input">
              <a-input v-model="baseForm.customId" />
            </a-form-model-item>
            <a-form-model-item label="需要配货" prop="prepareStatus" class="app_required-input">
              <a-switch v-model="baseForm.prepareStatus"></a-switch>
            </a-form-model-item>
            <a-form-model-item label="延迟打包" prop="delayPackage" class="app_required-input">
              <a-switch v-model="baseForm.delayPackage"></a-switch>
            </a-form-model-item>
            <a-form-model-item label="标签" prop="tags">
              <a-input v-model="baseForm.tags" />
              <p>
                <a-tag color="blue" @click="baseForm.tags = '换尺码'">换尺码</a-tag>
                <a-tag color="red" @click="baseForm.tags = '漏补'">漏补</a-tag>
              </p>
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark">
              <a-textarea v-model="baseForm.remark" :auto-size="{ minRows: 3, maxRows: 5 }"/>
            </a-form-model-item>
            <a-form-model-item label="出货仓库" prop="warehouseId" class="app_required-input">
              <a-select v-model="baseForm.warehouseId">
                <a-select-option :value="item.id" v-for="item in warehouseOptionFilter" :key="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="物流公司" prop="warehouseId" class="app_required-input">
              <a-select v-model="baseForm.companyId">
                <a-select-option :value="item.id" v-for="item in shippingCompanyOptions" :key="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="发货厂商" prop="supplierId" class="app_required-input" v-show="isSendBySupplierSelf">
              <a-select v-model="baseForm.supplierId" style="width: 100%;" :allowClear="false">
                <a-select-option :value="item.id" v-for="item in supplierOptions" :key="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="运输方式" prop="shipMethod" class="app_required-input">
              <div class="left-right-content" style="align-items: center;">
                <div class="full">
                  <a-select
                      show-search
                      option-filter-prop="children"
                      style="width: 100%"
                      :filter-option="shippingMethodsFilterOption"
                      v-model="baseForm.shipMethod"
                      option-label-prop="label"
                      dropdownClassName="shipping-method-dropdown"
                  >
                    <a-select-option :value="item.id" :label="item.name + (item.rateCurrency ? '（' + item.rate + ' ' + item.rateCurrency + '）' : '')" v-for="item in shippingMethodOptions" :key="item.id">
                      <div class="shipping-method-list-item-content">
                        {{item.name}}
                        <span v-if="item.rateCurrency" class="font-mini text-danger">（{{item.rate}} {{item.rateCurrency}}）</span>
                        <span class="font-mini text-danger" v-if="item.top">常用</span>
                        <div v-if="item.remark" class="text-muted font-mini">{{item.remark}}</div>
                      </div>
                    </a-select-option>
                  </a-select>
                </div>
                <a-button style="margin-left: 10px;" @click="loadShippingMethods(baseForm.companyId, true)" :loading="shippingMethodsLoading">刷新</a-button>
              </div>
            </a-form-model-item>

            <a-form-model-item label="店铺物流厂商" prop="shopShippingProviderId" class="app_required-input" v-show="selectedShippingCompany && selectedShippingCompany.trackNoImmediatelyReturn && shopShippingProviderOptions.length > 0">
              <a-select v-model="baseForm.shopShippingProviderId">
                <a-select-option :value="item.id" v-for="item in shopShippingProviderOptions" :key="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>

      <div class="card-panel" style="flex: 1;">
        <div class="header">
          <span class="title">订单列表（{{orders.length}}）</span>
          <div class="tools">
            <span v-show="stockLessMessage" class="text-danger font-mini" style="padding-right: 10px;">{{stockLessMessage}}</span>
            <span class="text-danger font-mini" v-if="submitSuccessOrders.length > 0 || submitErrorOrders.length > 0">
                成功提交 {{submitSuccessOrders.length}} 个订单，失败 {{submitErrorOrders.length}} 个订单。
            </span>
          </div>
        </div>
        <div class="body">
          <a-table
              v-if="!isSingleMode"
              size="middle"
              rowKey="id"
              :columns="orderColumns"
              :data-source="orders"
              :pagination="false"
              :loading="orderLoading"
          >

            <div slot="buttons" slot-scope="row">
              <a-button size="small" icon="delete" type="danger" @click="handleRemoveRow(row)"></a-button>
            </div>

            <div slot="orderNo" slot-scope="orderNo, row">
              <span class="text-primary">{{orderNo}}</span>
              <div class="text-muted font-mini" style="margin: 5px 0;" v-if="row.originOrderNo && row.originOrderNo !== orderNo">{{row.originOrderNo}}</div>
              <a-tooltip>
                <template slot="title">必填，发货编号，用于在物流平台作为唯一标识以及后续查询</template>
                <a-input v-model="row.deliveryOrderNo" :disabled="row.status === 'success' || loading"></a-input>
              </a-tooltip>
              <div class="font-mini text-success" style="margin-top: 5px;" v-if="row.deliveryCount > 0">已发货次数：{{row.deliveryCount}} </div>
            </div>

            <div slot="sku" slot-scope="v, row">
              <div class="image-list" v-if="row.shipProducts.length > 0">
                <div class="image-wrapper"
                     v-for="(pItem,index) in row.shipProducts"
                     :class="{'stock-less': baseForm.warehouseId && skuStockTotal[pItem.sku] < shipSkuTotal[pItem.sku]}"
                     :key="pItem.id"
                >
                  <a-tooltip>
                    <template slot="title">
                      {{pItem.sku}}
                      <span v-show="baseForm.warehouseId && skuStockTotal[pItem.sku] < shipSkuTotal[pItem.sku]"> 库存不足，差{{shipSkuTotal[pItem.sku] - skuStockTotal[pItem.sku]}}件</span>
                    </template>
                    <div class="image-item small" v-lazy:background-image="pItem.imageUrl" @click="$imageModal.open(getImageArray(row), index)">
                      <div class="sku-total" v-if="pItem.total > 1">{{pItem.total}}</div>
                    </div>
                  </a-tooltip>
                </div>
              </div>
              <a-button v-show="!loading && (row.status === 'wait' || row.status === 'error')" type="link" size="small" @click="$refs.products.open(row, selectedWarehouse, skuStockTotal, shipSkuTotal)">编辑</a-button>
            </div>

            <div slot="customer" slot-scope="val, row">
              <div>
                <strong>{{row.customer.lastName}} {{row.customer.firstName}}</strong> {{row.customer.phone}}
              </div>
              <div>
                {{row.customer.country}}, {{row.customer.state}}, {{row.customer.city}}, {{row.customer.address1}} {{row.customer.address2}} {{row.customer.postalCode}}
              </div>
              <a-button v-show="!loading && (row.status === 'wait' || row.status === 'error')" type="link" size="small" @click="$refs.address.open(row)">编辑</a-button>
            </div>

            <div slot="package" slot-scope="val, row">
              <div>
                <a-tooltip v-if="row.package.error">
                  <template slot="title">{{row.package.error}}</template>
                  <span class="text-danger">
                    {{row.package.weight}} / {{row.package.length}} x {{row.package.width}} x {{row.package.height}} / {{row.package.total}}
                  </span>
                </a-tooltip>
                <div v-else>
                  {{row.package.weight}} / {{row.package.length}} x {{row.package.width}} x {{row.package.height}} / {{row.package.total}}
                </div>
                <a-button v-show="!loading && (row.status === 'wait' || row.status === 'error')" type="link" size="small" @click="handleOpenPackageEditor(row)">编辑</a-button>
              </div>
              <div>
                <div>海关：{{row.parcel.name}}</div>
                <a-button v-show="!loading && (row.status === 'wait' || row.status === 'error')" type="link" size="small" @click="$refs.parcel.open(row)">编辑</a-button>
              </div>
            </div>

            <div slot="statusBar" slot-scope="val, row">
              <span v-if="row.status === 'wait'" class="text-muted font-mini">待提交</span>
              <a-icon type="loading" v-else-if="row.status === 'submit'" />
              <span class="text-success font-mini" v-else-if="row.status === 'success'">成功</span>
              <a-tooltip v-else>
                <template slot="title">{{row.statusMessage}}</template>
                <span class="text-danger font-mini">失败</span>
              </a-tooltip>
            </div>

          </a-table>


          <div v-if="isSingleMode">

            <a-card title="发货清单">
              <multi-delivery-products-content
                  :order="singleModeData"
                  :selected-warehouse="selectedWarehouse"
                  :sku-stocks="skuStockTotal"
                  :ship-sku-total="shipSkuTotal"
                  @skuTotalChanged="loadSkuStocks()">
              </multi-delivery-products-content>
            </a-card>

            <a-row :gutter="24" style="margin: 20px 0;">
              <a-col :span="12">
                <a-card title="客户单号">
                  <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
                    <a-form-model-item label="订单号">
                      <div class="text-primary">{{singleModeData.orderNo}}</div>
                      <div class="text-muted font-mini" style="margin: 5px 0;" v-if="singleModeData.originOrderNo && singleModeData.originOrderNo !== singleModeData.orderNo">{{singleModeData.originOrderNo}}</div>
                    </a-form-model-item>
                    <a-form-model-item label="发货编号" class="app_required-input">
                      <a-input v-model="singleModeData.deliveryOrderNo" :disabled="singleModeData.status === 'success' || loading"></a-input>
                      <div class="text-muted font-mini" style="line-height: normal;">必填，发货编号，用于在物流平台作为唯一标识以及后续查询。</div>
                      <div class="font-mini text-success" style="margin-top: 5px; line-height: normal;" v-if="singleModeData.deliveryCount > 0">已发货次数：{{singleModeData.deliveryCount}} </div>
                    </a-form-model-item>
                  </a-form>
                </a-card>
              </a-col>

              <a-col :span="12">
                <a-card title="包装尺寸">
                  <multi-delivery-package-content :order="singleModeData" :package-advice="findAdvice(singleModeData)"></multi-delivery-package-content>
                </a-card>
              </a-col>
            </a-row>

            <a-row :gutter="24">
              <a-col :span="12">
                <a-card title="海关信息">
                  <multi-delivery-parcel-content :order="singleModeData"></multi-delivery-parcel-content>
                </a-card>
              </a-col>

              <a-col :span="12">
                <a-card title="客户地址">
                  <multi-delivery-address-content :order="singleModeData"></multi-delivery-address-content>
                </a-card>
              </a-col>
            </a-row>
          </div>

        </div>
      </div>
    </div>

    <multi-delivery-products ref="products" @closed="handleProductModalClosed"></multi-delivery-products>
    <multi-delivery-address ref="address"></multi-delivery-address>
    <multi-delivery-package ref="package" @packageChanged="handleApplyPackageToOther"></multi-delivery-package>
    <multi-delivery-parcel ref="parcel" @parcelChanged="handleApplyParcelToOther"></multi-delivery-parcel>

    <a-button slot="buttons" @click="handleDownloadLabel" :loading="labelDownloading" v-show="submitSuccessOrders.length > 0">下载面单</a-button>
  </modal>
</template>

<script>
import moment from 'moment'
import {
  LOGISTICS_COMPANY_CANGSOU,
  LOGISTICS_COMPANY_LIST,
  LOGISTICS_COMPANY_SHIPOUT,
  LOGISTICS_COMPANY_SHIPPO,
  LOGISTICS_COMPANY_SUPPLIER
} from '@/constants/logistics'
import { listWarehouseEnabled } from '@/http/api/warehouse'
import {
  getCountries,
  getShippingCompanies,
  getShippingMethods,
  getShopShippingProvider,
  getDeliveryCountByOrders,
  delivery,
  printLabel
} from '@/http/api/order-delivery'
import { listOrdersByOrderNo } from '@/http/api/order'
import kit from '@/utils/kit'
import MultiDeliveryProducts from './multi-delivery-products'
import MultiDeliveryProductsContent from './multi-delivery-products-content'
import MultiDeliveryAddress from './multi-delivery-address'
import MultiDeliveryAddressContent from './multi-delivery-address-content'
import MultiDeliveryPackage from './multi-delivery-package'
import MultiDeliveryPackageContent from './multi-delivery-package-content'
import MultiDeliveryParcelContent from './multi-delivery-parcel-content'
import MultiDeliveryParcel from './multi-delivery-parcel'

import buildShipProductItem from './build-ship-product-item'
import { ORDER_DELIVERY_PREPARE_STATUS } from '@/constants/order-delivery-prepare-status'
import { CODE_VALIDATE_FAIL } from '@/http/error-code'
import { listAll as listShippingMethodAdvice } from '@/http/api/shipping-method-advice'
import { listSupplier } from '@/http/api/supplier'
import { getStockBySku } from '@/http/api/stock-product'

const logisticsList = [...LOGISTICS_COMPANY_LIST]
kit.arr.remove(logisticsList, { id: LOGISTICS_COMPANY_SHIPPO })

function buildBaseForm (customId) {
  return {
    customId: customId,
    tags: null,
    remark: null,
    companyId: null,
    warehouseId: null,
    shipMethod: null,
    shipMethodName: null,
    shopShippingProviderId: null,
    supplierId: null, // 厂商自发货
    prepareStatus: true,
    delayPackage: false
  }
}

export default {
  components: {
    MultiDeliveryProducts,
    MultiDeliveryProductsContent,
    MultiDeliveryAddress,
    MultiDeliveryAddressContent,
    MultiDeliveryPackage,
    MultiDeliveryPackageContent,
    MultiDeliveryParcel,
    MultiDeliveryParcelContent
  },
  data () {
    const shippingMethodsGroup = {}
    for (const item of logisticsList) {
      shippingMethodsGroup[item.id] = []
    }
    return {
      loading: false,
      baseForm: buildBaseForm(moment().format('yyyyMMDD')),
      warehouseOptions: [],
      countryOptions: [],
      shippingCompanyOptions: [],
      shippingMethodsGroup: shippingMethodsGroup,
      shippingMethodsLoading: false,
      shopShippingProviderOptions: [],
      supplierOptions: [],
      orders: [],
      orderLoading: false,
      orderColumns: [
        { title: '操作', width: 50, scopedSlots: { customRender: 'buttons' } },
        { title: '订单号', width: 200, dataIndex: 'orderNo', scopedSlots: { customRender: 'orderNo' } },
        { title: 'SKU', scopedSlots: { customRender: 'sku' } },
        { title: '客户', width: 300, scopedSlots: { customRender: 'customer' } },
        { title: '包装/海关', width: 160, scopedSlots: { customRender: 'package' } },
        { title: '状态', width: 80, scopedSlots: { customRender: 'statusBar' } }
      ],
      labelDownloading: false,
      shippingMethodAdvices: [],
      // sku的库存数量：sku > total
      skuStockTotal: {}
    }
  },
  watch: {
    'baseForm.companyId' (id) {
      if (id) {
        this.onShippingCompanyChanged(id)
      }
    },
    'baseForm.warehouseId' (id) {
      this.loadSkuStocks()
    }
  },
  computed: {
    isSingleMode () {
      return this.orders.length === 1
    },
    singleModeData () {
      if (this.isSingleMode) {
        return this.orders[0]
      } else {
        return null
      }
    },
    isSendBySupplierSelf () {
      return this.baseForm.companyId === LOGISTICS_COMPANY_SUPPLIER
    },
    warehouseOptionFilter () {
      if (this.baseForm.companyId === LOGISTICS_COMPANY_CANGSOU) {
        return this.warehouseOptions.filter(item => item.source === 'cangsou')
      } else if (this.baseForm.companyId === LOGISTICS_COMPANY_SHIPOUT) {
        return this.warehouseOptions.filter(item => item.source === 'shipout')
      } else {
        return this.warehouseOptions
      }
    },
    shippingMethodOptions () {
      if (this.baseForm.companyId) {
        return this.shippingMethodsGroup[this.baseForm.companyId]
      } else {
        return []
      }
    },
    selectedShippingCompany () {
      if (this.baseForm.companyId) {
        return this.shippingCompanyOptions.find(item => item.id === this.baseForm.companyId)
      } else {
        return null
      }
    },
    selectedWarehouse () {
      return this.warehouseOptions.find(item => item.id === this.baseForm.warehouseId)
    },
    submitSuccessOrders () {
      return this.orders.filter(item => item.status === 'success')
    },
    submitErrorOrders () {
      return this.orders.filter(item => item.status === 'error')
    },
    submitOrders () {
      return this.orders.filter(item => item.status === 'wait' || item.status === 'error')
    },
    /**
     * 发货SKU的总数量
     * return { sku: total }
     */
    shipSkuTotal () {
      const skuTotal = {}
      for (const o of this.orders) {
        for (const p of o.shipProducts) {
          const t = skuTotal[p.sku] || 0
          skuTotal[p.sku] = p.total + t
        }
      }
      return skuTotal
    },
    stockLessMessage () {
      let t = 0
      for (const sku of Object.keys(this.shipSkuTotal)) {
        const shipTotal = this.shipSkuTotal[sku]
        const stock = this.skuStockTotal[sku]
        if (shipTotal > stock) {
          t++
        }
      }
      return t > 0 ? `${t}个SKU库存不足。` : null
    }
  },
  methods: {
    open (orderNoList) {
      if (orderNoList && orderNoList.length > 0) {
        this.baseForm = buildBaseForm(moment().format('yyyyMMDD'))
        this.$refs.myModal.open()
        this.loadOrders(orderNoList)
        this.initOptions()

        getShippingCompanies().success(resp => (this.shippingCompanyOptions = resp.data)).send()
        listShippingMethodAdvice().success(resp => (this.shippingMethodAdvices = resp.data)).send()
        if (this.supplierOptions.length === 0) {
          listSupplier().success(resp => (this.supplierOptions = resp.data)).send()
        }
      } else {
        this.$message.error('请提供发货订单。')
        this.$refs.myModal.close()
      }
    },
    onClosed () {
      this.orders = []
    },
    loadOrders (orderNoList) {
      this.orderLoading = true
      listOrdersByOrderNo()
        .complete(() => (this.orderLoading = false))
        .success(resp => {
          const list = []
          const idList = []
          let shopType = null
          let shopId = null
          for (const order of resp.data) {
            if (shopType == null) {
              shopType = order.shopType
            }
            if (shopType != null && shopType !== order.shopType) {
              this.$info({ title: '', content: '不同的店铺平台的订单不能一起发货：' + shopType + ' / ' + order.shopType })
              this.$refs.myModal.close()
              break
            }
            shopId = order.shopId
            idList.push(order.id)
            const o = {
              id: order.id,
              orderNo: order.orderNo,
              originOrderNo: order.originOrderNo,
              deliveryOrderNo: null,
              deliveryCount: 0, // 已发货的数量
              shipProducts: [],
              status: 'wait', // wait, submit, success, error
              statusMessage: null,
              customer: {
                countryCode: order.receiverCountryCode,
                country: order.receiverCountry,
                stateCode: null,
                state: order.receiverState,
                city: order.receiverCity,
                address1: order.receiverAddress1,
                address2: order.receiverAddress2,
                postalCode: order.receiverPostalCode,
                firstName: order.receiverFirstName,
                lastName: order.receiverLastName,
                phone: order.receiverPhone
              },
              package: {
                weight: 0.4, // 单位千克
                length: 20, // 单位厘米
                width: 14, // 单位厘米
                height: 6, // 单位厘米
                total: 1, // 外包装数量
                error: null // 提交前会做一个检查，如果有不符合规定的，会有提示消息
              },
              parcel: { // 海关申报
                price: 2,
                currency: 'USD',
                weight: 0.1,
                name: 'Pajamas',
                cname: '睡衣',
                materialCn: '丝绸',
                materialEn: 'Silk',
                usageCn: '穿戴',
                usageEn: 'Wear',
                // https://www.hsbianma.com/Code/6208291010.html
                hsCode: '6208291010',
                total: 1
              }
            }
            for (const product of order.productItems) {
              const shipItem = buildShipProductItem(product)
              shipItem.orderProductId = product.id
              o.shipProducts.push(shipItem)
            }
            list.push(o)
            this.updatePackageInfoFromOrderProduct(o)
          }
          this.orders = list

          getDeliveryCountByOrders()
            .success(resp => {
              for (const order of this.orders) {
                const deliveryCount = resp.data[order.id]
                order.deliveryOrderNo = 'XINSIKJ-' + order.orderNo + '-' + (deliveryCount + 1)
                order.deliveryCount = deliveryCount
              }
            })
            .send(idList)

          this.loadShopShippingProvider(shopId)
        })
        .send(orderNoList)
    },
    initOptions () {
      if (this.warehouseOptions.length === 0) {
        listWarehouseEnabled().success(resp => (this.warehouseOptions = resp.data)).send()
      }
      if (this.countryOptions.length === 0) {
        getCountries().success(resp => (this.countryOptions = resp.data)).send()
      }
    },
    shippingMethodsFilterOption (input, option) {
      return (option.componentOptions.children[0].children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0)
    },
    onShippingCompanyChanged (companyId) {
      if (this.shippingMethodsGroup[companyId].length === 0) {
        this.loadShippingMethods(companyId)
      }
      if (!this.selectedShippingCompany.trackNoImmediatelyReturn) {
        this.baseForm.shopShippingProviderId = null
      }
    },
    loadShopShippingProvider (shopId) {
      getShopShippingProvider()
        .success(resp => (this.shopShippingProviderOptions = resp.data))
        .send(shopId)
    },
    loadShippingMethods (companyId, reload) {
      this.shippingMethodsLoading = true
      getShippingMethods()
        .complete(() => (this.shippingMethodsLoading = false))
        .success(resp => {
          this.shippingMethodsGroup[companyId] = resp.data
          if (companyId === LOGISTICS_COMPANY_SUPPLIER) {
            this.baseForm.shipMethod = resp.data[0].id
          } else {
            this.baseForm.shipMethod = null
          }
        })
        .send(companyId, reload || false)
    },
    getImageArray (record) {
      const arr = []
      for (const p of record.shipProducts) {
        const item = {
          url: p.imageUrl,
          infos: [
            { name: '订单号', value: record.orderNo },
            { name: 'SKU', value: p.sku },
            { name: '数量', value: p.total }
          ],
          title: p.title
        }
        if (record.remark) {
          item.infos.push({ name: '客户备注', value: record.remark, type: 'remark' })
        }
        arr.push(item)
      }
      return arr
    },
    findAdvice (order) {
      let advice = null
      for (const adv of this.shippingMethodAdvices) {
        if (adv.company === this.baseForm.companyId && adv.shipCode === this.baseForm.shipMethod) {
          if (!adv.countryCode) {
            advice = adv
          }
          if (adv.countryCode === order.customer.countryCode) {
            advice = adv
            break
          }
        }
      }
      return advice
    },
    checkBulkAndWeight () {
      let errorCount = 0
      for (const order of this.submitOrders) {
        const pkg = order.package
        const advice = this.findAdvice(order)
        if (advice) {
          const msg = []
          if (advice.weightMin > 0 && advice.weightMax > 0 && (pkg.weight <= advice.weightMin || pkg.weight > advice.weightMax)) {
            msg.push(`重量不合（${pkg.weight}kg），应：大于${advice.weightMin}kg，且小于等于${advice.weightMax}kg`)
          }
          if (pkg.length > advice.sizeLength || pkg.width > advice.sizeWidth || pkg.height > advice.sizeHeight) {
            msg.push(`体积不合（${pkg.length}x${pkg.width}x${pkg.height}cm），应：${advice.sizeLength}x${advice.sizeWidth}x${advice.sizeHeight}cm`)
          }
          if (msg.length > 0) {
            order.package.error = msg.join(' / ')
            errorCount++
          }
        }
      }
      return errorCount
    },
    handleStartSubmit () {
      if (kit.str.isBlank(this.baseForm.warehouseId)) {
        return this.$message.error('请选择仓库。')
      }
      if (kit.str.isBlank(this.baseForm.companyId)) {
        return this.$message.error('请选择物流公司。')
      }
      if (kit.str.isBlank(this.baseForm.shipMethod)) {
        return this.$message.error('请选择物流方式。')
      }
      if (this.selectedShippingCompany.trackNoImmediatelyReturn && this.shopShippingProviderOptions.length > 0 && !this.baseForm.shopShippingProviderId) {
        return this.$message.error('店铺平台物流厂商未选择。')
      }
      const errorCount = this.checkBulkAndWeight()
      let msg
      if (errorCount > 0) {
        msg = `发现${errorCount}个包裹的重量体积不符合发货渠道限制，如果仍要继续发货，请点确定按钮。`
      } else {
        msg = '确定要下单发货吗？'
      }
      this.$confirm({
        title: '确认',
        content: msg,
        onOk: () => {
          this.submitDeliveryForAllOrders()
        }
      })
    },
    submitDeliveryForAllOrders () {
      async function fn (context) {
        context.loading = true
        const orders = context.submitOrders
        for (const order of orders) {
          await (function () {
            return new Promise((resolve, reject) => {
              context.submitDelivery(order, (success, message) => {
                if (success) {
                  order.status = 'success'
                  order.deliveryId = message
                  if (orders.length === 1) {
                    context.$message.success('发货已经提交。')
                  }
                } else {
                  order.status = 'error'
                  order.statusMessage = message
                  if (orders.length === 1) {
                    context.$error({ title: 'Error', content: message })
                  }
                }
                resolve()
              })
            })
          }())
        }
        context.$emit('deliveryCompleted')
        context.loading = false
        this.loadSkuStocks()
      }
      fn(this)
    },
    submitDelivery (order, completedCallback) {
      if (this.baseForm.shipMethod) {
        this.baseForm.shipMethodName = this.shippingMethodsGroup[this.baseForm.companyId]
          .find(item => item.id === this.baseForm.shipMethod)
          .name
      }
      order.status = 'submit'

      const skuTotalList = []
      for (const item of order.shipProducts) {
        skuTotalList.push({ sku: item.sku, total: item.total })
      }

      const params = {
        orderId: order.id,
        orderProductList: skuTotalList,
        customId: this.baseForm.customId,
        tags: this.baseForm.tags,
        supplierId: this.baseForm.supplierId, // 厂商自发货
        remark: null,
        personalTaxNo: null,
        companyId: this.baseForm.companyId,
        warehouseId: this.baseForm.warehouseId,
        shipMethod: this.baseForm.shipMethod,
        shipMethodName: this.baseForm.shipMethodName,
        weight: order.package.weight, // 单位千克
        length: order.package.length, // 单位厘米
        width: order.package.width, // 单位厘米
        height: order.package.height, // 单位厘米
        packageTotal: order.package.total,
        orderNo: order.deliveryOrderNo,
        prepareStatus: this.baseForm.prepareStatus ? ORDER_DELIVERY_PREPARE_STATUS.yes : ORDER_DELIVERY_PREPARE_STATUS.no,
        delayPackage: this.baseForm.delayPackage,
        receiveAddress: {
          countryCode: order.customer.countryCode,
          country: order.customer.country,
          stateCode: null,
          state: order.customer.state,
          city: order.customer.city,
          address1: order.customer.address1,
          address2: order.customer.address2,
          postalCode: order.customer.postalCode,
          name: (order.customer.lastName || '') + ' ' + (order.customer.firstName || ''),
          phone: order.customer.phone
        },
        parcel: Object.assign({}, order.parcel)
      }
      delivery()
        .success(resp => {
          completedCallback(true, resp.data)
        })
        .error(resp => {
          let msg = resp.message
          if (resp.code === CODE_VALIDATE_FAIL) {
            const msgList = []
            for (const d of resp.details) {
              msgList.push(d.message)
            }
            msg = msgList.join(' / ')
          }
          completedCallback(false, msg)
        })
        .send(params)
    },
    handleOpenPackageEditor (order) {
      this.$refs.package.open(order, this.findAdvice(order))
    },
    handleApplyPackageToOther (order) {
      for (const o of this.orders) {
        kit.obj.getProperties(o.package, order.package)
      }
      this.$message.success('设置已应用。')
    },
    handleApplyParcelToOther (order) {
      for (const o of this.orders) {
        kit.obj.getProperties(o.parcel, order.parcel)
      }
      this.$message.success('设置已应用。')
    },
    handleDownloadLabel () {
      const idList = []
      for (const o of this.submitSuccessOrders) {
        idList.push(o.deliveryId)
      }
      this.labelDownloading = true
      printLabel()
        .complete(() => (this.labelDownloading = false))
        .success(resp => {
          window.open(resp.data)
        })
        .send(idList)
    },
    handleRemoveRow (row) {
      kit.arr.removeItem(this.orders, row)
    },
    updatePackageInfoFromOrderProduct (order) {
      let flag = false
      let w = 0
      for (const p of order.shipProducts) {
        if (p.skuWeight && p.skuWeight > 0) {
          w += p.skuWeight * p.total
        }

        if (p.skuLength && p.skuWidth && p.skuHeight &&
            p.skuLength > 0 && p.skuWidth > 0 && p.skuHeight > 0) {
          order.package.length = p.skuLength
          order.package.width = p.skuWidth
          order.package.height = p.skuHeight
          flag = true
        }
      }
      if (w > 0) {
        order.package.weight = kit.num.toFixedTrimZero(w, 2)
        flag = true
      }
      return flag
    },
    handleProductModalClosed (order) {
      const flag = this.updatePackageInfoFromOrderProduct(order)
      if (flag) {
        this.$message.success('根据发货产品信息，包装的重量和体积已经自动变更，请确认。')
      }
      this.loadSkuStocks()
    },
    loadSkuStocks () {
      if (this.baseForm.warehouseId) {
        const skuList = Object.keys(this.shipSkuTotal)
        getStockBySku()
          .success(resp => {
            const map = {}
            const skuKeys = Object.keys(resp.data)
            for (const sku of skuKeys) {
              const stock = resp.data[sku][0].quantity
              map[sku] = stock
            }
            for (const sku of Object.keys(this.shipSkuTotal)) {
              if (!map[sku]) {
                map[sku] = 0
              }
            }
            this.skuStockTotal = map
          })
          .send(skuList.join(','), this.baseForm.warehouseId)
      } else {
        this.skuStockTotal = {}
      }
    }
  }
}
</script>

<style lang="less" scoped>

.container {
  display: flex;
}
.card-panel .body {
  max-height: 500px;
  overflow-y: auto;
}

.shipping-method-dropdown .ant-select-dropdown-menu {
  overflow-x: hidden !important;
}
.shipping-method-dropdown .shipping-method-list-item-content {
  word-break: break-all;
  white-space: normal;
}

.image-list {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .image-wrapper {
    margin: 5px;
  }
  .image-wrapper.stock-less .image-item {
    border-color: #d72e34;
    border-width: 2px;
  }
  .image-item {
    position: relative;
    overflow: visible;
  }
  .sku-total {
    position: absolute;
    top: -10px;
    right: -5px;
    z-index: 100;
    color: #fff;
    background-color: #4586d4;
    padding: 0 4px;
    border-radius: 3px;
    font-size: 12px;
  }
}
</style>
